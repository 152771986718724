import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { CgClose } from "react-icons/cg"
import { AiFillStar } from "react-icons/ai"

export default function SuccessStoriesModal({
  modalIsOpen,
  onModalClose,
  selectedClient,
}) {
  if (!modalIsOpen) return null

  const handleUnsetBodyOverflowFreeze = () => {
    document.body.style.overflow = "unset"
  }

  return (
    <>
      <SuccessModalOverlay>
        <SuccessModalWrapper>
          <SuccessModalContainer
            className={modalIsOpen ? "success-story-modal__overflow" : null}
          >
            <CloseIconContainer>
              <CloseIcon onClick={onModalClose} />
            </CloseIconContainer>
            <ClientlVideoContainer>
              <ClientVideoInnerSmall>
                <iframe
                  width="460"
                  height="249"
                  className="success-story-modal__video"
                  src={
                    "https://www.youtube.com/embed/" +
                    selectedClient.frontmatter.youtube_id
                  }
                  title={selectedClient.frontmatter.youtube_title}
                  style={{ border: "0" }}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </ClientVideoInnerSmall>
              <ClientVideoInnerLarge>
                <div className="iframe-container">
                  <iframe
                    width="560"
                    height="315"
                    className="success-story-modal__video"
                    src={
                      "https://www.youtube.com/embed/" +
                      selectedClient.frontmatter.youtube_id
                    }
                    title={selectedClient.frontmatter.youtube_title}
                    style={{ border: "0" }}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </ClientVideoInnerLarge>
            </ClientlVideoContainer>

            <CtaButtonContainer>
              <Link to="/case-study1" style={{ textDecoration: "none" }}>
                <CtaButtonInner>
                  <span>Watch Free Training</span>
                </CtaButtonInner>
              </Link>
              <TestimominalLinkContainer>
                <span>
                  Or, read more about&nbsp;
                  <Link
                    onClick={(handleUnsetBodyOverflowFreeze, onModalClose)}
                    style={{ textDecoration: "underline" }}
                    // to="/"
                    to={`/success-stories/${selectedClient.slug}`}
                  >
                    {selectedClient.frontmatter.first_name}'s story here
                  </Link>
                  .
                </span>
              </TestimominalLinkContainer>
            </CtaButtonContainer>

            <HeadlineContainer>
              <Title>{selectedClient.frontmatter.headline}</Title>
            </HeadlineContainer>
            <RatingContainer>
              <span className="success-story-modal__label">Rating:</span>
              <ClientRating>
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </ClientRating>
            </RatingContainer>
            <NameLevelContainer>
              <NameLevelInnerContainer>
                <div>
                  <span className="success-story-modal__label">Name:</span>
                  <span>{selectedClient.frontmatter.name}</span>
                </div>
              </NameLevelInnerContainer>
            </NameLevelContainer>
            <NicheContainer>
              <div>
                <span className="success-story-modal__label">Niche:</span>
                <span>{selectedClient.frontmatter.niche}</span>
              </div>
            </NicheContainer>
            <LocationContainer>
              <div>
                <span className="success-story-modal__label">Location:</span>
                <span>
                  {selectedClient.frontmatter.city}, &nbsp;
                  {selectedClient.frontmatter.state}, &nbsp;
                  {selectedClient.frontmatter.country}
                </span>
              </div>
            </LocationContainer>
            <DescriptionContainer>
              <div className="success-story-modal__label">Description:</div>
              <div>{selectedClient.frontmatter.summary}</div>
              <Spacer />
            </DescriptionContainer>
          </SuccessModalContainer>
        </SuccessModalWrapper>
      </SuccessModalOverlay>
      {/* <div>{selectedClient.frontmatter.name}</div> */}
      {/* <Link to={`/blog/${node.slug}`}>{node.frontmatter.title}</Link> */}
    </>
  )
}

const Spacer = styled.div`
  height: 150px;
  width: 100%;
`

const SuccessModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 6000;
  background-color: rgba(0, 0, 0, 0.7);

  @media (max-width: 550px) {
    background-color: white;
  }
`
const SuccessModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`
const SuccessModalContainer = styled.div`
  position: absolute;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  padding: 20px;
  top: 70px;
  width: 80%;
  display: grid;
  grid-template-columns: 1fr, 1fr;
  grid-auto-rows: minmax(auto, auto);
  grid-template-areas:
    "close close"
    "video title"
    "video rating"
    "video nameLevel"
    "video niche"
    "video location"
    "cta description"
    "link description";
  max-width: 1060px;
  min-width: 952;
  background-color: #fff;
  /* border: 1px solid #f76707; */
  z-index: 5000;
  border-radius: 10px;

  @media (max-width: 1030px) {
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(auto, auto);
    grid-template-areas:
      "close "
      "title"
      "video"
      "cta"
      "link"
      "rating"
      "nameLevel"
      "niche"
      "location"
      "description";

    height: auto;
    width: 90%;
    margin: 5px;
    padding: 20px;
    padding-bottom: 40px;
  }

  @media (max-width: 550px) {
    top: 10px;
    width: 100%;
    padding: 3px;
    padding-bottom: 30px;
  }
`

const CloseIconContainer = styled.div`
  grid-row: span 2;
  text-align: right;
  grid-area: close;

  @media (max-width: 550px) {
    padding-right: 17px;
    padding-bottom: 25px;
  }
`
const ClientlVideoContainer = styled.div`
  /* border: 1px solid #f76707; */
  padding-bottom: 40px;
  width: 100%;
  grid-area: video;
`
const ClientVideoInnerSmall = styled.div`
  @media (max-width: 1030px) {
    display: none;
  }
`

const ClientVideoInnerLarge = styled.div`
  display: none;
  @media (max-width: 1030px) {
    display: flex;
    justify-content: center;
    display: block;
    width: auto;
  }
`

const CtaButtonContainer = styled.div`
  margin: 0;
  padding: 0;
  /* border: 1px solid #f76707; */
  grid-area: cta;
`
const CtaButtonInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: hsl(123, 46%, 34%);
  border-color: hsl(123, 46%, 14%);
  color: #fff;
  height: 50px;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;

  &:hover {
    background-color: hsl(123, 46%, 34%);
    box-shadow: 0 0 5px 0 hsl(123, 46%, 24%);
  }
`

const TestimominalLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  /* border: 1px solid #f76707; */
  grid-area: link;

  @media (max-width: 550px) {
    margin-bottom: 30px;
  }
`

const HeadlineContainer = styled.div`
  /* border: 1px solid #f76707; */
  padding: 0 20px;
  grid-column-start: 1;
  grid-column-end: -1;
  grid-area: title;

  @media (max-width: 1030px) {
    padding-bottom: 20px;
  }
`
const Title = styled.h1`
  text-align: left;
  font-size: 26px;
  font-weight: 700;
`
const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  /* border: 1px solid #f76707; */
  padding: 10px 0 30px 20px;
  grid-area: rating;

  @media (max-width: 550px) {
    padding-bottom: 20px;
  }
`
const ClientRating = styled.span``

const NameLevelContainer = styled.div`
  /* border: 1px solid #f76707; */
  grid-area: nameLevel;
`
const NameLevelInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  @media (max-width: 1030px) {
    display: block;
  }
`

const NicheContainer = styled.div`
  /* border: 1px solid #f76707; */
  padding-left: 20px;
  grid-area: niche;
`

const LocationContainer = styled.div`
  /* border: 1px solid #f76707; */
  padding-left: 20px;
  grid-area: location;
`

const DescriptionContainer = styled.div`
  /* border: 1px solid #f76707; */
  padding: 0 20px;
  grid-area: description;
`

const CloseIcon = styled(CgClose)`
  cursor: pointer;
  font-size: 1.5rem;
  color: #8a8a8a;

  @media (max-width: 550px) {
    font-size: 2.5rem;
  }
`
const StarIcon = styled(AiFillStar)`
  font-size: 20px;
  color: #ffc15b;
`
