import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { NavigationBar } from "../../components/main/NavigationBar"
import Dropdown from "../../components/main/Dropdown"
import SocialFooter from "../../components/main/SocialFooter"
import DisclosureFooter from "../../components/main/DisclosureFooter"
import { AiFillStar } from "react-icons/ai"
import { BiPlay } from "react-icons/bi"
import SuccessStoriesModal from "./SuccessStoriesModal"
import favicon from "../../assets/images/nlc-favicon.png"

export default function SuccessStories({ data }) {
  const [isOpen, setIsOpen] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [selectedClient, setSelectedClient] = useState()

  function handleImageClick(id) {
    // setSelectedClientId(id)
    setSelectedClient(data.allMdx.nodes.find(node => node.id === id))
    setModalIsOpen(true)
  }

  const handleModalClose = () => {
    setSelectedClient(null)
    setModalIsOpen(false)
  }

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  // Freeze the body background if the modal is open
  useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  }, [modalIsOpen])

  return (
    <>
      <Helmet
        title="Success Stories | 100+ Life Changing Success Stories"
        meta={[
          {
            name: "description",
            content:
              "Below you'll find 100+ stories from content creators & filmmakers who have quit their 9-5 jobs, 50+ people earning over 6-figures & 4 members over the 7 figure mark. Their experiences are undeniable proof that our trainings offer some of the most advanced, cutting edge, and EFFECTIVE strategies for building wealth & freedom as a content creator. Our mission at Next Level Creators is to empower you with result-driven education programs to succeed at every level of a content creation career, from anywhere in the world! Every program is designed to help you achieve a higher income, faster, and more cost effective than Film Schools or other online programs. So, should you invest in one of our programs? Only you know the answer, and to help you make your decision, here are 100+ reviews from our clients...",
          },
          { name: "keywords", content: "...." },
        ]}
        link={[{ rel: "shortcut icon", type: "image/png", href: `${favicon}` }]}
      />
      <Dropdown isOpen={isOpen} toggle={toggle} />
      <NavigationBar toggle={toggle} />
      <Body>
        <ContentContainer>
          <IntroductionContainer>
            <IntroductionHeader>
              100+ Life Changing Success Stories
            </IntroductionHeader>
            <IntroductionText>
              <p>
                We believe in SHOWING you real proof that our strategies &
                programs deliver results consistently to the people who do the
                work!
              </p>
              <p>
                Below you'll find 100+ stories from content creators &
                filmmakers who have quit their 9-5 jobs, 50+ people earning over
                6-figures & 4 members over the 7 figure mark. Their experiences
                are undeniable proof that our trainings offer some of the most
                advanced, cutting edge, and EFFECTIVE strategies for building
                wealth & freedom as a content creator. Our mission at Next Level
                Creators is to empower you with result-driven education programs
                to succeed at every level of a content creation career, from
                anywhere in the world! Every program is designed to help you
                achieve a higher income, faster, and more cost effective than
                Film Schools or other online programs. So, should you invest in
                one of our programs? Only you know the answer, and to help you
                make your decision, here are 100+ reviews from our clients...
              </p>
            </IntroductionText>

            <SuccessStoriesModal
              modalIsOpen={modalIsOpen}
              onModalClose={handleModalClose}
              data={data}
              selectedClient={selectedClient}
            ></SuccessStoriesModal>
          </IntroductionContainer>
          <TestimonialContainer className="success-stories__testimonial-container" />
          <StoryCardContainer>
            {data.allMdx.nodes.map(node => (
              <StoryCard key={node.id}>
                <ClientImageContainer
                  id={node.id}
                  className="success-stories__client-image-container"
                  onClick={() => handleImageClick(node.id)}
                >
                  <PlayCircle className="success-stories__play-circle">
                    <PlayIcon className="success-stories__play-icon" />
                  </PlayCircle>
                  <GatsbyImage
                    image={getImage(node.frontmatter.image)}
                    alt={node.frontmatter.image_alt}
                  />
                </ClientImageContainer>
                <ClientInfoContainer>
                  <ClientInfo>
                    <ClientName>{node.frontmatter.name}</ClientName>
                    <ClientLocation>
                      {node.frontmatter.city},&nbsp;
                      {node.frontmatter.state}
                    </ClientLocation>
                  </ClientInfo>
                  <ClientRating>
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                  </ClientRating>
                </ClientInfoContainer>
              </StoryCard>
            ))}
          </StoryCardContainer>
        </ContentContainer>
      </Body>
      <SocialFooter />
      <section className="themes__panel-black">
        <DisclosureFooter />
      </section>
    </>
  )
}

const Body = styled.div`
  display: grid;
  justify-items: center;
  width: 100vw;
  flex-wrap: wrap;
  padding: 30px;
  margin: 0;
  border: 0;
  padding-top: 110px;
  background-color: #ecedef;
  font-family: "Open Sans", "Roboto", Arial, Helvetica, sans-serif;

  @media (max-width: 1199px) {
  }

  @media (max-width: 970px) {
  }

  @media (max-width: 620px) {
    padding-left: 5px;
    padding-right: 5px;
  }
`

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1280px;
`

const IntroductionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;

  @media (max-width: 1199px) {
    max-width: 1199px;
  }

  @media (max-width: 970px) {
    max-width: 970px;
  }

  @media (max-width: 620px) {
    max-width: 620px;
  }
`

const IntroductionHeader = styled.h1`
  font-family: "Open Sans", "Roboto", Arial, Helvetica, sans-serif;
  font-size: 3rem;
  font-weight: 700;
  color: #444444;

  @media (max-width: 1199px) {
    font-size: 2.5rem;
  }

  @media (max-width: 970px) {
    font-size: 2rem;
  }

  @media (max-width: 620px) {
    font-size: 1.5rem;
  }
`

const IntroductionText = styled.div`
  font-family: "Open Sans", "Roboto", Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #8a8a8a;
  margin: 10px 0;
`

const StoryCardContainer = styled.div`
  display: grid;
  gap: 30px;
  justify-content: space-around;
  grid-template-columns: repeat(4, 1fr);
  max-width: 1200px;

  @media (max-width: 1199px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 970px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 620px) {
    grid-template-columns: 1fr;
  }
`
const StoryCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 20px 50px rgb(0 0 0 / 20%);

  @media (max-width: 1199px) {
    max-width: 336px;
  }

  @media (max-width: 970px) {
    max-width: 448px;
  }

  @media (max-width: 620px) {
    max-width: 620px;
  }

  &:hover {
    box-shadow: 0 20px 50px rgb(0 0 0 / 40%);
  }
`

const ClientImageContainer = styled.div``

const ClientInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: white;
  font-style: inherit;
  height: 90px;
  width: 100%;
  cursor: pointer;
`
const ClientInfo = styled.div`
  padding-top: 5px;
  padding-left: 5px;
`

const ClientRating = styled.div`
  margin-top: 10px;
  padding-right: 5px;
`

const ClientName = styled.h5`
  font-family: "Open Sans", "Roboto", Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: 700;
  color: #000000;
  padding: 0;
  margin: 0;
`
const ClientLocation = styled.div`
  font-family: "Open Sans", "Roboto", Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #8a8a8a;
  padding: 5px 0 0 0;
  margin: 0;
`
const StarIcon = styled(AiFillStar)`
  font-size: 16px;
  color: #ffc15b;
`
const PlayCircle = styled.div``

const PlayIcon = styled(BiPlay)``

const TestimonialContainer = styled.div``
