import * as React from "react"
import Layout from "../../components/layout"
import { graphql } from "gatsby"
import Seo from "../../components/seo"
import SuccessStories from "../../components/success-stories/SuccessStories"

const SuccessStoriesPage = ({ data }) => {
  return (
    <Layout pageTitle="Success Stories">
      <Seo title="Next Level Creators | Success Stories" />
      <SuccessStories data={data} />
    </Layout>
  )
}

export default SuccessStoriesPage

export const query = graphql`
  query {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          image_alt
          city
          country
          level
          name
          first_name
          niche
          rating
          state
          headline
          summary
          youtube_id
          youtube_title
        }
        id
        slug
      }
    }
  }
`
// export const query = graphql`
//   query {
//     allMdx(sort: { fields: frontmatter___date, order: DESC }) {
//       edges {
//         node {
//           frontmatter {
//             city
//             date(formatString: "MMMM D, YYYY")
//             image_alt
//             name
//             rating
//             state
//             image {
//               childImageSharp {
//                 gatsbyImageData
//               }
//             }
//           } //           id
//           slug
//         }
//       }
//     }
//   }
// `
